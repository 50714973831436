<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.SP.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <pz-identity-document
            ref="identity"
            v-model="formulario1.identity"
            prop="identity"
            type="person"
            :type-label="$t('forms_flujo.form1_persona.SP.tipo_doc')"
            :number-label="$t('forms_flujo.form1_persona.SP.identity_doc')"
          />
        </el-col>
        <el-col
          :xl="{span: 6}"
          :lg="{span: 6}"
          :md="{span: 6}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <pz-phone-number
            v-model="formulario1.telefono"
            :error="errors.get('telefono')"
            default-country-code="+34"
            required
          />
        </el-col>
        <el-col
          :xl="{span: 6}"
          :lg="{span: 6}"
          :md="{span: 6}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="contrato_movil"
            :label="$t('forms_flujo.form1_persona.SP.contrato_movil')"
          >
            <el-select
              ref="contrato_movil"
              v-model="formulario1.contrato_movil"
              clearable
              filterable
              :placeholder="$t('forms_flujo.form1_persona.SP.contrato_movil')"
            >
              <el-option
                v-for="item in tipo_contrato"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.SP.contrato_movil')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombres"
            :label="$t('forms_flujo.form1_persona.SP.nombre')"
            :error="errors.get('nombres')"
          >
            <el-input
              ref="nombres"
              v-model="formulario1.nombres"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.nombre')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellidos"
            :label="$t('forms_flujo.form1_persona.SP.apellidos')"
            :error="errors.get('apellido_paterno')"
          >
            <el-input
              ref="apellidos"
              v-model="formulario1.apellidos"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.apellidos')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nacionalidad"
            :label="$t('forms_flujo.form1_persona.SP.nacionalidad')"
          >
            <el-select
              ref="nacionalidad"
              v-model="formulario1.nacionalidad"
              clearable
              filterable
              :placeholder="$t('forms_flujo.form1_persona.SP.nacionalidad')"
            >
              <el-option
                v-for="item in paises"
                :key="item.id"
                :label="item.nacionalidad"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.SP.nacionalidad')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_nacimiento"
            :label="$t('forms_flujo.form1_persona.SP.fecha_nacimiento')"
            :error="errors.get('fecha_nacimiento')"
          >
            <el-date-picker
              ref="fecha_nacimiento"
              v-model="formulario1.fecha_nacimiento"
              type="date"
              editable
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :picker-options="pickerOptions1"
              :placeholder="$t('forms_flujo.form1_persona.SP.fecha_nacimiento')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="mail"
            :label="$t('forms_flujo.form1_persona.SP.mail')"
            :error="errors.get('mail')"
          >
            <el-input
              ref="mail"
              v-model="formulario1.mail"
              :type="'text'"
              auto-complete="on"
              :placeholder="$t('forms_flujo.form1_persona.SP.mail')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="confirmmail"
            :label="$t('forms_flujo.form1_persona.SP.confirmmail')"
            :error="errors.get('confirmmail')"
          >
            <el-input
              ref="mail"
              v-model="formulario1.confirmmail"
              :type="'text'"
              auto-complete="on"
              :placeholder="$t('forms_flujo.form1_persona.SP.confirmmail')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="ingreso_mensual"
            :label="$t('forms_flujo.form1_persona.SP.ingreso_mensual')"
            :error="errors.get('ingreso_mensual')"
          >
            <el-input
              ref="ingreso_mensual"
              v-model.number="formulario1.ingreso_mensual"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.ingreso_mensual')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="cuota_maxima"
            :label="$t('forms_flujo.form1_persona.SP.cuota_maxima')"
            :error="errors.get('cuota_maxima')"
          >
            <el-input
              ref="cuota_maxima"
              v-model.number="formulario1.cuota_maxima"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.cuota_maxima')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="destino_prestamo"
            :label="$t('forms_flujo.form1_persona.SP.destino_prestamo')"
            :error="errors.get('destino_prestamo')"
          >
            <el-autocomplete
              ref="destino_prestamo"
              v-model="formulario1.destino_prestamo"
              :fetch-suggestions="getSuggest"
              :placeholder="$t('forms_flujo.form1_persona.SP.destino_prestamo')"
            />
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 24}"
                :lg="{span: 24}"
                :md="{span: 24}"
                :sm="{span: 24}"
                :xs="{span: 24}"
                class="row justify-content-between"
        >
          <el-form-item
                  prop="info_extra.autorizo"
                  :error="errors.get('autorizo')"
          >
            <el-checkbox
                    ref="info_extra.autorizo"
                    v-model="formulario1.info_extra.autorizo"
                    :span="11"
            >
              <div style="white-space: initial;">
                Autorizo a Reddo Credit a comunicar mis datos identificativos (nombre y apellidos) y de
                contacto (dirección de correo electrónico y número de teléfono) a Rose & Pages, S.L., Correduría de
                Seguros, con NIF B87699443 y domicilio en Madrid, C/ Hermosilla, 80, para que pueda remitirme
                comunicaciones comerciales en relación con el ofrecimiento de seguros comercializados por la compañía
                que podrían ser de mi interés.
              </div>
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
                :xl="{span: 24}"
                :lg="{span: 24}"
                :md="{span: 24}"
                :sm="{span: 24}"
                :xs="{span: 24}"
                class="row justify-content-between"
        >
          <el-form-item
                  prop="info_extra.autorizo2"
                  :error="errors.get('autorizo2')"
          >
            <el-checkbox
                    ref="info_extra.autorizo2"
                    v-model="formulario1.info_extra.autorizo2"
                    :label=" $t('forms_flujo.form1_persona.SP.autorizo2')"
            >
              <div style="white-space: initial;">
                Autorizo a Reddo Credit a comunicar mis datos identificativos (nombre y apellidos) y de
                contacto (dirección de correo electrónico y número de teléfono) a Reddo Finance, S.L., Walddo,
                con NIF B88611595 y domicilio en Madrid, C/ Hermosilla, 80, para que pueda remitirme
                comunicaciones comerciales en relación con el ofrecimiento de seguros comercializados por
                la compañía que podrían ser de mi interés.
              </div>
            </el-checkbox>
          </el-form-item>
        </el-col>
        <el-col
                :xl="{span: 24}"
                :lg="{span: 24}"
                :md="{span: 24}"
                :sm="{span: 24}"
                :xs="{span: 24}"
                class="row justify-content-between"
        >
          <el-form-item
                  prop="info_extra.terminos_condiciones"
                  :error="errors.get('info_extra.terminos_condiciones')"
          >
            <el-checkbox
                    ref="info_extra.terminos_condiciones"
                    v-model="formulario1.info_extra.terminos_condiciones"
            >
              <div style="white-space: initial;">
                He leído y acepto los <a
                      href="https://reddocredit.com/terminos"
                      target="_blank"
              >Términos y Condiciones</a> , la  <a
                      href="https://reddocredit.com/privacidad"
                      target="_blank"
              >Política de Privacidad</a> y
                <a
                        href="https://reddocredit.com/info-general"
                        target="_blank"
                >la Información Europea Normalizada del Contrato</a>, y consiento que mis datos personales
                sean procesados de conformidad con las Condiciones Generales y la Política de Privacidad.
              </div>
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required, type,EspacioBlanco,onlyLeter} from "@/commons/utils/Rules";
import {getPaises} from "@/routes/api/resources";
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import {isEmpty} from "@/commons/utils/functions";
import PzPhoneNumber from "@/commons/forms/Fields/PzPhoneNumber";

export default {
    name: 'PzPersonFormEs1',
    components: {PzPhoneNumber, PzIdentityDocument, PzFormFlujo},
    mixins: [PzBaseForm1],
    data() {
        var check_Terms = (rule, value, callback) => {
            if (!(this.formulario1.info_extra.terminos_condiciones) ) {
                return callback(new Error(this.$t('rules.should_accept_terms2')));
            } else {
                return callback();
            }
        };
        var check_mail = (rule, value, callback) => {
            if (!(value)) {
                return callback(new Error(this.$t('rules.field_mandatory',{name:'Confirmar Correo electrónico'})));
            } else if (value!=this.formulario1.mail) {
                return callback(new Error(this.$t('rules.check_email')));
            } else {
                return callback();
            }
        };
        return {
            marital_status: [],
            tipo_contrato: [
                {id:'Contratado',name:'Contratado'},
                {id:'Pre-Pago',name:'Pre-Pago'},
            ],
            paises: [],
            suggest: [],
            formulario1: {
                nombres: '',
                apellidos: '',
                telefono: {},
                nacionalidad: '',
                fecha_nacimiento: '',
                mail: '',
                destino_prestamo: '',
                cuota_maxima: '',
                ingreso_mensual: '',
                identity: {},
                info_extra: {
                    terminos_condiciones: '',
                    autorizo: '',
                    autorizo2: '',
                },
                confirmmail:'',
            },
            rules_formulario1: {
                'identity.document': [required('')],
                'identity.number': [required(''),EspacioBlanco()],
                nombres: [required(''),EspacioBlanco(),onlyLeter()],
                apellidos: [required(''),EspacioBlanco(),onlyLeter()],
                fecha_nacimiento: [required(''),],
                nacionalidad: [required('')],
                mail: [required(''), type({field: '', type: 'email'})],
                cuota_maxima: [required(''), type({field: '', type: 'number'}),],
                ingreso_mensual: [required(''), type({field: '', type: 'number'}),],
                destino_prestamo: [required(''),],
                estado_civil_id: [required('')],
                telefono: [type({field: '', type: 'number'})],
                'info_extra.terminos_condiciones':[{validator: check_Terms, trigger: 'blur'}],
                confirmmail:[{validator: check_mail, trigger: 'blur'}]

            },
            pickerOptions1: {
                disabledDate(time) {
                    return Date.now() < time.getTime();
                },
            },
        };
    },
    created() {
        this.fetchData();
        this.suggest = Object.values(this.$t('credit_motive')).map((value) => {
            return {value: value}
        });
    },
    methods: {
        fetchData() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            let paises = getPaises().then((res) => {
                this.paises = res.data;
            }).catch(() => {
                this.paises = [];
            });
            Promise.all([paises]).
                finally(() => {
                    this.$store.dispatch('loader/down', {trigger: this.$options.name})
                });
        },
        getSuggest(queryString, cb) {
            let suggest = this.suggest;
            const self = this;
            let results = !isEmpty(queryString) && !isEmpty(suggest) ? suggest.filter(function (value) {
                return (self._.deburr(value.value).toLowerCase().indexOf(self._.deburr(queryString).toLowerCase()) >= 0);
            }) : suggest;
            cb(results);
        },


    },

}
</script>

